import { HttpErrorResponse } from '@angular/common/http'
import { createReducer, on } from '@ngrx/store'

import { CategoryMatrix, ComponentLevelMetrics, DatasetsChart, LoadingState, PaginatedData } from '~core/models'
import { Comment } from '~features/comments/types'
import { setError, setIsLoading } from '~store/utils.store'

import { Insight, Post, Reel, Story } from '../models'
import * as InstagramAccountActions from './instagram-account.actions'

export const instagramAccountFeatureKey = 'instagramAccount'

export interface State {
  activePost: Post
  activeReel: Reel
  comments: LoadingState<PaginatedData<Comment>>
  activeStory: Story
  audienceChange: ComponentLevelMetrics & { chart: DatasetsChart }
  audienceChangeIsLoading: boolean
  error: HttpErrorResponse
  insights: Insight[]
  insightsIsLoading: boolean
  insightsOverview: any // todo: type
  renderedStories: number
  onlineFollowersIsLoading: boolean
  onlineFollowersPerHour: CategoryMatrix
  postsIsLoading: boolean
  previousInsights: Insight[]
  reelsIsLoading: boolean
  stories: Story[]
  storiesActivity: ComponentLevelMetrics & { chart: DatasetsChart }
  storiesActivityIsLoading: boolean
  storiesIsLoading: boolean
}

export const initialState: State = {
  activePost: null,
  activeReel: null,
  activeStory: null,
  comments: null,
  error: null,
  insights: [],
  insightsOverview: null,
  insightsIsLoading: false,
  storiesActivityIsLoading: false,
  storiesActivity: null,
  audienceChangeIsLoading: false,
  audienceChange: null,
  renderedStories: 30,
  onlineFollowersIsLoading: false,
  onlineFollowersPerHour: null,
  postsIsLoading: false,
  previousInsights: [],
  reelsIsLoading: false,
  stories: [],
  storiesIsLoading: false,
}

const setInsights = (state, action): State => ({
  ...state,
  insightsIsLoading: false,
  insights: action.payload.data.slice(action.payload.data.length / 2),
  previousInsights: action.payload.data.slice(0, action.payload.data.length / 2),
  error: null,
})

const setInsightsOverview = (state, action): State => ({
  ...state,
  insightsOverview: action.payload.data,
  error: null,
})

const setStoriesActivity = (state, action): State => ({
  ...state,
  storiesActivityIsLoading: false,
  storiesActivity: action.payload.data,
  error: null,
})

const setAudienceChangeInsights = (state, action): State => ({
  ...state,
  audienceChangeIsLoading: false,
  audienceChange: action.payload.data,
  error: null,
})

const setOnlineFollowersPerHour = (state, action): State => ({
  ...state,
  onlineFollowersIsLoading: false,
  onlineFollowersPerHour: action.payload.data,
  error: null,
})

const setComments = (state, action): State => ({
  ...state,
  comments: {
    ...action.payload,
    data: [...(state.comments?.data ?? []), ...action.payload.data],
  },
  error: null,
})

const setActivePost = (state, action): State => ({
  ...state,
  activePost: action.payload.data,
  postsIsLoading: false,
  error: null,
})

const unsetActivePost = (state): State => ({
  ...state,
  activePost: null,
  comments: null,
})

const setRenderedStories = (state): State => ({
  ...state,
  renderedStories: state.renderedStories + 30,
})

const setActiveStory = (state, action): State => ({
  ...state,
  activeStory: action.payload.data,
  storiesIsLoading: false,
  error: null,
})

const unsetActiveStory = (state): State => ({
  ...state,
  activeStory: null,
})

const setActiveReel = (state, action): State => ({
  ...state,
  activeReel: action.payload.data,
  reelsIsLoading: false,
  error: null,
})

const unsetActiveReel = (state): State => ({
  ...state,
  activeReel: null,
})

const resetState = (): State => initialState

export const reducer = createReducer(
  initialState,

  // Insights
  on(InstagramAccountActions.loadInsights, setIsLoading('insights')),
  on(InstagramAccountActions.loadInsightsSuccess, setInsights),
  on(InstagramAccountActions.loadInsightsOverviewSuccess, setInsightsOverview),
  on(InstagramAccountActions.loadOnlineFollowersPerHour, setIsLoading('onlineFollowers')),
  on(InstagramAccountActions.loadOnlineFollowersPerHourSuccess, setOnlineFollowersPerHour),
  on(InstagramAccountActions.loadStoriesActivity, setIsLoading('storiesActivity')),
  on(InstagramAccountActions.loadStoriesActivitySuccess, setStoriesActivity),
  on(InstagramAccountActions.loadAudienceChange, setIsLoading('audienceChange')),
  on(InstagramAccountActions.loadAudienceChangeSuccess, setAudienceChangeInsights),

  // Posts
  on(InstagramAccountActions.loadPostSuccess, setActivePost),
  on(InstagramAccountActions.unsetActivePost, unsetActivePost),
  on(InstagramAccountActions.loadPostCommentsSuccess, setComments),

  // Stories
  on(InstagramAccountActions.loadStorySuccess, setActiveStory),
  on(InstagramAccountActions.unsetActiveStory, unsetActiveStory),
  on(InstagramAccountActions.renderMoreStories, setRenderedStories),

  // Reels
  on(InstagramAccountActions.loadReelSuccess, setActiveReel),
  on(InstagramAccountActions.unsetActiveReel, unsetActiveReel),

  // Errors
  on(
    InstagramAccountActions.loadInsightsError,
    InstagramAccountActions.loadInsightsOverviewError,
    InstagramAccountActions.loadStoriesActivityError,
    InstagramAccountActions.loadAudienceChangeError,
    InstagramAccountActions.loadOnlineFollowersPerHourError,
    InstagramAccountActions.loadPostError,
    InstagramAccountActions.loadReelError,
    setError,
  ),

  // Reset state
  on(InstagramAccountActions.resetState, resetState),
)

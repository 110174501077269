import { createAction, props } from '@ngrx/store'

import { ProjectDateRangeRequest } from '~common/components/filters/date-range/date-range.model'
import { ComponentLevelMetrics, JsonResponse, PaginatedData } from '~core/models'
import { CategoryMatrix } from '~core/models'
import { CommentListRequest, Comment } from '~features/comments/types'
import { createHTTPActions } from '~store/utils.actions'

import {
  Insight,
  InsightsRequest,
  InstagramAccount,
  Post,
  PostRequest,
  Reel,
  ReelRequest,
  Story,
  StoryRequest,
} from '../models'

export const setInstagramAccount = createAction(
  '[Instagram Account] Set InstagramAccount',
  props<{ payload: InstagramAccount }>(),
)

// Insights

export const [loadInsights, loadInsightsSuccess, loadInsightsError] = createHTTPActions<
  InsightsRequest,
  JsonResponse<Insight[]>
>('[Instagram Account] Load Insights')

export const [loadInsightsOverview, loadInsightsOverviewSuccess, loadInsightsOverviewError] = createHTTPActions<
  ProjectDateRangeRequest,
  JsonResponse<any>
>('[Instagram Account] Load Insights Overview')

export const [loadStoriesActivity, loadStoriesActivitySuccess, loadStoriesActivityError] = createHTTPActions<
  ProjectDateRangeRequest,
  JsonResponse<ComponentLevelMetrics>
>('[Instagram Account] Load Stories Activity Insights')

export const [loadAudienceChange, loadAudienceChangeSuccess, loadAudienceChangeError] = createHTTPActions<
  ProjectDateRangeRequest,
  JsonResponse<any>
>('[Instagram Account] Load Audience Change Insights')

export const [loadOnlineFollowersPerHour, loadOnlineFollowersPerHourSuccess, loadOnlineFollowersPerHourError] =
  createHTTPActions<ProjectDateRangeRequest, JsonResponse<CategoryMatrix>>(
    '[Instagram Account] Load Online Followers Per Hour',
  )

// Posts
export const [loadPost, loadPostSuccess, loadPostError] = createHTTPActions<PostRequest, JsonResponse<Post>>(
  '[Instagram Account] Load Post',
)

export const [loadPostComments, loadPostCommentsSuccess, loadPostCommentsError] = createHTTPActions<
  CommentListRequest,
  PaginatedData<Comment>
>('[Instagram Account] Load Post Comments')

export const unsetActivePost = createAction('[Instagram Account] Unset Active Post')

// Stories
export const renderMoreStories = createAction('[Instagram Account] Render more stories')

export const [loadStory, loadStorySuccess, loadStoryError] = createHTTPActions<StoryRequest, JsonResponse<Story>>(
  '[Instagram Account] Load Story',
)

export const unsetActiveStory = createAction('[Instagram Account] Unset Active Story')

// Reels
export const [loadReel, loadReelSuccess, loadReelError] = createHTTPActions<ReelRequest, JsonResponse<Reel>>(
  '[Instagram Account] Load Reel',
)

export const unsetActiveReel = createAction('[Instagram Account] Unset Active Reel')

export const resetState = createAction('[Instagram Account] Reset state')

import { HttpErrorResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { of } from 'rxjs'
import { catchError, exhaustMap, map } from 'rxjs/operators'

import { withPreviousPeriod } from '~core/utils/api'
import { InstagramAccountApiService } from '~features/instagram-account/services/instagram-account-api.service'

import * as InstagramAccountActions from './instagram-account.actions'

@Injectable()
export class InstagramAccountEffects {
  loadAudienceChange = createEffect(() => {
    return this.actions$.pipe(
      ofType(InstagramAccountActions.loadAudienceChange),
      exhaustMap(({ payload }) => {
        return this.instagramAccountApiService.audienceChangeInsights(payload).pipe(
          map((audienceChange) => InstagramAccountActions.loadAudienceChangeSuccess(audienceChange)),
          catchError((err: HttpErrorResponse) => of(InstagramAccountActions.loadAudienceChangeError(err))),
        )
      }),
    )
  })

  loadInsights$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(InstagramAccountActions.loadInsights),
      exhaustMap(({ payload }) => {
        return this.instagramAccountApiService.insights(withPreviousPeriod(payload)).pipe(
          map((insights) => InstagramAccountActions.loadInsightsSuccess(insights)),
          catchError((err: HttpErrorResponse) => of(InstagramAccountActions.loadInsightsError(err))),
        )
      }),
    )
  })

  /*
  TODO
  loadInsightsOverview$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(InstagramAccountActions.loadInsightsOverview),
      exhaustMap(({ payload }) => {
        return combineLatest([this.instagramAccountOverviewService.index(payload)]).pipe(
          map((insights) => InstagramAccountActions.loadInsightsSuccess(insights)),
          catchError((err: HttpErrorResponse) => of(InstagramAccountActions.loadInsightsError(err))),
        )
      }),
    )
  })*/

  loadOnlineFollowersPerHour$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(InstagramAccountActions.loadOnlineFollowersPerHour),
      exhaustMap(({ payload }) => {
        return this.instagramAccountApiService.onlineFollowersPerHour(payload).pipe(
          map((onlineFollowersPerHour) =>
            InstagramAccountActions.loadOnlineFollowersPerHourSuccess(onlineFollowersPerHour),
          ),
          catchError((err: HttpErrorResponse) => of(InstagramAccountActions.loadOnlineFollowersPerHourError(err))),
        )
      }),
    )
  })

  loadPost$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(InstagramAccountActions.loadPost),
      exhaustMap(({ payload }) => {
        return this.instagramAccountApiService.post(payload).pipe(
          map((post) => InstagramAccountActions.loadPostSuccess(post)),
          catchError((err: HttpErrorResponse) => of(InstagramAccountActions.loadPostError(err))),
        )
      }),
    )
  })

  loadPostComments$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(InstagramAccountActions.loadPostComments),
      exhaustMap(({ payload }) => {
        return this.instagramAccountApiService.postComments(payload).pipe(
          map((commentData) => InstagramAccountActions.loadPostCommentsSuccess(commentData)),
          catchError((err: HttpErrorResponse) => of(InstagramAccountActions.loadPostCommentsError(err))),
        )
      }),
    )
  })

  loadReel$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(InstagramAccountActions.loadReel),
      exhaustMap(({ payload }) => {
        return this.instagramAccountApiService.reel(payload).pipe(
          map((reel) => InstagramAccountActions.loadReelSuccess(reel)),
          catchError((err: HttpErrorResponse) => of(InstagramAccountActions.loadReelError(err))),
        )
      }),
    )
  })

  loadStoriesActivity$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(InstagramAccountActions.loadStoriesActivity),
      exhaustMap(({ payload }) => {
        return this.instagramAccountApiService.storiesActivityInsights(payload).pipe(
          map((storiesActivity) => InstagramAccountActions.loadStoriesActivitySuccess(storiesActivity)),
          catchError((err: HttpErrorResponse) => of(InstagramAccountActions.loadStoriesActivityError(err))),
        )
      }),
    )
  })
  loadStory$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(InstagramAccountActions.loadStory),
      exhaustMap(({ payload }) => {
        return this.instagramAccountApiService.story(payload).pipe(
          map((story) => InstagramAccountActions.loadStorySuccess(story)),
          catchError((err: HttpErrorResponse) => of(InstagramAccountActions.loadStoryError(err))),
        )
      }),
    )
  })

  constructor(
    private actions$: Actions,
    private instagramAccountApiService: InstagramAccountApiService,
  ) {}
}

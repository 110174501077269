import { ApplicationConfig } from '@angular/core'
import { provideTranslocoScope } from '@jsverse/transloco'
import { provideEffects } from '@ngrx/effects'
import { provideState } from '@ngrx/store'

import { translocoScopeLoader } from '~core/utils'

import { InstagramAccountEffects } from './store/instagram-account.effects'
import * as fromInstagramAccount from './store/instagram-account.reducer'

export const igAccountConfig: ApplicationConfig['providers'] = [
  provideState(fromInstagramAccount.instagramAccountFeatureKey, fromInstagramAccount.reducer),
  provideEffects(InstagramAccountEffects),
]

export const igAccountI18nConfig: ApplicationConfig['providers'] = [
  // Provided via translationsConfig in app.config
  provideTranslocoScope({
    scope: 'instagramAccount',
    loader: translocoScopeLoader((lang: string, root: string) => import(`./${root}/${lang}.json`)),
  }),
]
